html {
  background-color: #f794a9;
}

.treeTop {
  width: 80vw;
  height: 65vw;

  margin-bottom: -25vw;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  mask-image: url("Tree2.svg");
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;

  display: inline-block;
}

.title {
  color: #f5f5dc;
  font-family: GochiHand;
}

.pricing {
  color: #f5f5dc;
  font-family: GochiHand;
  font-size: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.instructions {
  color: #f5f5dc;
  font-family: GochiHand;
  font-size: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.divider {
  width: 100vw;
  height: 2px;
  background-color: white;
}

@font-face {
  font-family: "GochiHand";
  src: URL("GochiHand-Regular.ttf") format("truetype");
}

#dicks {
  font-family: GochiHand;
  border-collapse: collapse;
  width: 100%;
}

#dicks td,
#dicks th {
  border: 1px solid #ddd;
  padding: 8px;
}

#dicks tr {
  background-color: #f5f5dc;
}

#dicks th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f5f5dc;
}
